import React, { useState } from 'react';
import "../login/Login.scss";

const AffiliateFooter = () => {
  const [year] = useState(new Date().getFullYear())
  return (
    <>
        <footer className='affiliate-footer'>
            <p>&copy; {year} <a href="https://data2go.mobi/" target="_blank" rel="noreferrer">data2go</a> | Designed by <a href="https://bluehouse-technology.co.uk" target="_blank" rel="noreferrer">Bluehouse Technology</a></p>
        </footer>
    </>
  )
}
export default AffiliateFooter